<template>
  <div>
    <p
      class="block"
      v-for="(para, index) of allParagraphs()"
      v-bind:key="index"
      v-bind:class="{ guide: isGuide(index) }"
    >
      {{ para }}
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: mapState({
    paragraphs: (state) => state.paragraphs,
  }),
  methods: {
    isGuide(index) {
      return index == 0 || index == this.allParagraphs().length - 1
    },
    allParagraphs() {
      let paras = [];

      this.paragraphs.forEach(para => {
        paras.push(...para.split("||"))
      });

      console.log(paras)
      return paras;
    },
  }
};
</script>

<style scoped>
p {
  text-align: left;
}
.guide {
  font-weight: bold;
}
</style>