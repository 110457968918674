<template>
  <div class="section">
    <div class="container">
      <p class="title is-2">The Story</p>
      <p class="subtitle is-4">Well, so far ...</p>

      <EpisodeNav
        v-on:episode_nav="go_to_episode"
        :sea="season"
        :epi="episode"
      />
      <Episode style="margin-top: 10px" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Episode from "@/components/episode.vue";
import EpisodeNav from "@/components/episode_nav.vue";

export default {
  name: "episode",
  components: {
    Episode,
    EpisodeNav,
  },
  computed: {
    ...mapState({
      seasons: (state) => state.seasons,
      season: (state) => state.season,
      episode: (state) => state.episode,
    }),
    current_season() {
      return this.seasons[this.season - 1];
    },
    latest_season() {
      return this.seasons[this.seasons.length - 1];
    },
  },

  async created() {
    await this.show_seasons();

    this.go_to_episode(
      this.$route.params.seasonid !== undefined
        ? this.$route.params.seasonid
        : this.latest_season.season,
      this.$route.params.episodeid !== undefined
        ? this.$route.params.episodeid
        : this.latest_season.episode_count
    );
  },
  methods: {
    ...mapActions(["show_seasons", "show_episode"]),
    async go_to_episode(season_id, episode_id) {
      if (this.season != season_id)
        this.$store.commit("set_current_season", season_id);

      // Check for step into previous season
      if (Number(episode_id) === 0)
        episode_id = this.current_season.episode_count;
      await this.load_episode(episode_id);

      this.$router.push({
        name: "episode",
        params: { seasonid: season_id, episodeid: episode_id },
      });
    },
    async load_episode(episode_id) {
      await this.show_episode({
        season_id: this.season,
        episode_id: episode_id,
      });
    },
  },
};
</script>

<style scoped>
</style>