<template>
<div class="section">
  <div class="container">
    <p class="title is-2">Characters</p>
    <p class="subtitle is-4">The main players in J-Bitch, Wasteland Warrior!</p>
    <div class="columns">
      <div class="column">
        <div class="card">
          <div class="card-image">
            <figure class="image is-4by3">
              <img
                src="https://bulma.io/images/placeholders/640x480.png"
                alt="Placeholder image"
              />
            </figure>
          </div>
          <div class="card-content">
            <div class="content">
              <p class="title is-4">J-Bitch</p>
              The main character and the captain of The Odysius. Pretty much a
              guy who's in WAY over his head. Sometimes you just got to fake it 'til you make it,
              amiright?!?
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="card">
          <div class="card-image">
            <figure class="image is-4by3">
              <img
                src="https://bulma.io/images/placeholders/640x480.png"
                alt="Placeholder image"
              />
            </figure>
          </div>
          <div class="card-content">
            <div class="content">
              <p class="title is-4">A-Hole</p>
              First Officer of The Odysius who's totally ready to step into the
              captancy (she's even willing to open the position with a well
              placed "lazer" shot).
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="card">
          <div class="card-image">
            <figure class="image is-4by3">
              <img
                src="https://bulma.io/images/placeholders/640x480.png"
                alt="Placeholder image"
              />
            </figure>
          </div>
          <div class="card-content">
            <div class="content">
              <p class="title is-4">T-Bone</p>
              The Odysius' current navigator and A-Hole's main squeeze (well, only squeeze
                    because everyone else she knew has been dead like 1000 years).
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="card">
          <div class="card-image">
            <figure class="image is-4by3">
              <img
                src="https://bulma.io/images/placeholders/640x480.png"
                alt="Placeholder image"
              />
            </figure>
          </div>
          <div class="card-content">
            <div class="content">
              <p class="title is-4">Lisa</p>
              The Odysius' control AI named after a really old, fruit-based personal computer.
                    Still not sure how they managed to create a digital tool from an apple.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>