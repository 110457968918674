<template>
  <div class="section">
    <div class="container">
      <p class="title is-2">Metadata</p>
      <p class="subtitle is-4">No one reads about pages</p>

      <p class="block">
        Some time ago, a friend sent a picure of her husband all hooked up to
        his CPAP machine which just goes to show that you need to be very
        specific when asking for risque pictures. In any case, he looked like
        some post-apocalytic character (or that maybe he'd been exploring
        crashed spaceships full of eggs).
      </p>

      <p class="block">
        As a joke, I sent out what is now the initial episode of "J-Bitch,
        Wasteland Warrior!" to the group chat. It went over way better than I
        thought and the serial was created. Since then, with a few breaks (one
        quite a bit longer than I intended), the story has progressed
        organically. That just means I wing it each episode without a plan or a
        guide.
      </p>

      <p class="block">
        Since they seemed to like the story (and none of us have any qualms
        about telling each other when we are being dumb-asses), I figured others
        would too and thus this website was born! Now you can all bask in the
        awesomeness of this crazy-cool adventure!
      </p>

      <p class="block">
        Oh yeah, "Metadata" sounds way cooler than "About" (no one goes to those
        pages anyways).
      </p>

      <p class="title is-3">Funding</p>

      <p class="block">
        I really, really hate ads and I really, really don't want to populate my
        site with them. I get them, they are great if you are trying to make a
        buck, but I don't want to push the latest widget on you so I can get
        $0.0003 per click or something like that.
      </p>

      <p class="block">
        So, to support this site, I'm going to let you opt in to the funding
        opportunity and hook me up with cash when the mood strikes you via
        <a href="https://www.buymeacoffee.com/jbtchwastep"
          ><img
            src="bmc-full-logo-no-background.png"
            alt="buymeacoffee.com"
            target="_blank"
            style="max-height: 30px" /></a
        >. Or, you can just come here and enjoy the story, that's cool too.
      </p>

      <p class="title is-3">Artwork</p>

      <p class="block">
        It's coming, I promise. My "art consultant" has a bit of a busy social
        and academic schedule. Apparently, keeping a roof over her head, food on
        the table and clothes on her back does not get me a priority bump (kids
        these days!). Maybe when Christmas rolls around and Santa "forgets" to
        bring her something, she'll get a move on!
      </p>
    </div>
  </div>
</template>
