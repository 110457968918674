<template>
  <button class="button" @click="previous">
    <i class="bi bi-chevron-left"></i>
  </button>
  <button class="button" @click="next">
    <i class="bi bi-chevron-right"></i>
  </button>
  &nbsp;
  <div class="dropdown" v-bind:class="{ 'is-active': season_active }">
    <div class="dropdown-trigger">
      <button
        class="button"
        aria-haspopup="true"
        aria-controls="season-menu"
        @click="season_active = !season_active"
      >
        <span>Season {{ sea }}</span>
        <span class="icon is-small">
          <i class="bi bi-caret-down-fill" aria-hidden="true"></i>
        </span>
      </button>
    </div>
    <div class="dropdown-menu" id="season-menu" role="menu">
      <div class="dropdown-content">
        <a
          class="dropdown-item"
          v-for="s in seasons"
          :key="s.season"
          @click="
            season_active = false;
            $emit('episode_nav', s.season, 1);
          "
        >
          Season {{ s.season }}
        </a>
      </div>
    </div>
  </div>
  <div class="dropdown" v-bind:class="{ 'is-active': episode_active }">
    <div class="dropdown-trigger">
      <button
        class="button"
        aria-haspopup="true"
        aria-controls="episode-menu"
        @click="episode_active = !episode_active"
      >
        <span>Episode {{ epi }}</span>
        <span class="icon is-small">
          <i class="bi bi-caret-down-fill" aria-hidden="true"></i>
        </span>
      </button>
    </div>
    <div class="dropdown-menu" id="episode-menu" role="menu">
      <div class="dropdown-content">
        <a
          class="dropdown-item"
          v-for="e in current_season.episode_count"
          :key="e"
          @click="
            episode_active = false;
            $emit('episode_nav', season, e);
          "
        >
          Episode {{ e }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "episode nav",
  props: ["sea", "epi"],
  emits: ["episode_nav"],
  data() {
    return {
      season_active: false,
      episode_active: false,
    };
  },
  computed: {
    ...mapState({
      seasons: (state) => state.seasons,
      season: (state) => state.season,
      episode: (state) => state.episode,
    }),
    current_season() {
      return this.seasons[this.season - 1];
    },
    latest_season() {
      return this.seasons[this.seasons.length - 1];
    },
  },
  methods: {
    previous() {
      let new_episode = this.episode - 1;

      if (new_episode > 0) {
        this.$emit("episode_nav", this.season, new_episode);
      } else if (this.season > 1) {
        this.$emit("episode_nav", this.season - 1, new_episode);
      }
    },
    next() {
      let new_episode = this.episode + 1;

      if (new_episode <= this.current_season.episode_count) {
        this.$emit("episode_nav", this.season, new_episode);
      } else if (this.season < this.latest_season.season) {
        this.$emit("episode_nav", this.season + 1, 1);
      }
    },
    do_log(item) {
      console.log(item);
      this.nav_season = item;
    },
  },
};
</script>

<style scoped>
</style>